import _functionCall from "../internals/function-call";
import _functionUncurryThis from "../internals/function-uncurry-this";
import _fixRegexpWellKnownSymbolLogic from "../internals/fix-regexp-well-known-symbol-logic";
import _anObject from "../internals/an-object";
import _isNullOrUndefined from "../internals/is-null-or-undefined";
import _requireObjectCoercible from "../internals/require-object-coercible";
import _speciesConstructor from "../internals/species-constructor";
import _advanceStringIndex from "../internals/advance-string-index";
import _toLength from "../internals/to-length";
import _toString from "../internals/to-string";
import _getMethod from "../internals/get-method";
import _regexpExecAbstract from "../internals/regexp-exec-abstract";
import _regexpStickyHelpers from "../internals/regexp-sticky-helpers";
import _fails from "../internals/fails";
var call = _functionCall;
var uncurryThis = _functionUncurryThis;
var fixRegExpWellKnownSymbolLogic = _fixRegexpWellKnownSymbolLogic;
var anObject = _anObject;
var isNullOrUndefined = _isNullOrUndefined;
var requireObjectCoercible = _requireObjectCoercible;
var speciesConstructor = _speciesConstructor;
var advanceStringIndex = _advanceStringIndex;
var toLength = _toLength;
var toString = _toString;
var getMethod = _getMethod;
var regExpExec = _regexpExecAbstract;
var stickyHelpers = _regexpStickyHelpers;
var fails = _fails;
var UNSUPPORTED_Y = stickyHelpers.UNSUPPORTED_Y;
var MAX_UINT32 = 4294967295;
var min = Math.min;
var push = uncurryThis([].push);
var stringSlice = uncurryThis("".slice);

// Chrome 51 has a buggy "split" implementation when RegExp#exec !== nativeExec
// Weex JS has frozen built-in prototypes, so use try / catch wrapper
var SPLIT_WORKS_WITH_OVERWRITTEN_EXEC = !fails(function () {
  // eslint-disable-next-line regexp/no-empty-group -- required for testing
  var re = /(?:)/;
  var originalExec = re.exec;
  re.exec = function () {
    return originalExec.apply(this, arguments);
  };
  var result = "ab".split(re);
  return result.length !== 2 || result[0] !== "a" || result[1] !== "b";
});
var BUGGY = "abbc".split(/(b)*/)[1] === "c" ||
// eslint-disable-next-line regexp/no-empty-group -- required for testing
"test".split(/(?:)/, -1).length !== 4 || "ab".split(/(?:ab)*/).length !== 2 || ".".split(/(.?)(.?)/).length !== 4 ||
// eslint-disable-next-line regexp/no-empty-capturing-group, regexp/no-empty-group -- required for testing
".".split(/()()/).length > 1 || "".split(/.?/).length;

// @@split logic
fixRegExpWellKnownSymbolLogic("split", function (SPLIT, nativeSplit, maybeCallNative) {
  var internalSplit = "0".split(undefined, 0).length ? function (separator, limit) {
    return separator === undefined && limit === 0 ? [] : call(nativeSplit, this, separator, limit);
  } : nativeSplit;
  return [
  // `String.prototype.split` method
  // https://tc39.es/ecma262/#sec-string.prototype.split
  function split(separator, limit) {
    var O = requireObjectCoercible(this);
    var splitter = isNullOrUndefined(separator) ? undefined : getMethod(separator, SPLIT);
    return splitter ? call(splitter, separator, O, limit) : call(internalSplit, toString(O), separator, limit);
  },
  // `RegExp.prototype[@@split]` method
  // https://tc39.es/ecma262/#sec-regexp.prototype-@@split
  //
  // NOTE: This cannot be properly polyfilled in engines that don't support
  // the 'y' flag.
  function (string, limit) {
    var rx = anObject(this);
    var S = toString(string);
    if (!BUGGY) {
      var res = maybeCallNative(internalSplit, rx, S, limit, internalSplit !== nativeSplit);
      if (res.done) return res.value;
    }
    var C = speciesConstructor(rx, RegExp);
    var unicodeMatching = rx.unicode;
    var flags = (rx.ignoreCase ? "i" : "") + (rx.multiline ? "m" : "") + (rx.unicode ? "u" : "") + (UNSUPPORTED_Y ? "g" : "y");
    // ^(? + rx + ) is needed, in combination with some S slicing, to
    // simulate the 'y' flag.
    var splitter = new C(UNSUPPORTED_Y ? "^(?:" + rx.source + ")" : rx, flags);
    var lim = limit === undefined ? MAX_UINT32 : limit >>> 0;
    if (lim === 0) return [];
    if (S.length === 0) return regExpExec(splitter, S) === null ? [S] : [];
    var p = 0;
    var q = 0;
    var A = [];
    while (q < S.length) {
      splitter.lastIndex = UNSUPPORTED_Y ? 0 : q;
      var z = regExpExec(splitter, UNSUPPORTED_Y ? stringSlice(S, q) : S);
      var e;
      if (z === null || (e = min(toLength(splitter.lastIndex + (UNSUPPORTED_Y ? q : 0)), S.length)) === p) {
        q = advanceStringIndex(S, q, unicodeMatching);
      } else {
        push(A, stringSlice(S, p, q));
        if (A.length === lim) return A;
        for (var i = 1; i <= z.length - 1; i++) {
          push(A, z[i]);
          if (A.length === lim) return A;
        }
        q = p = e;
      }
    }
    push(A, stringSlice(S, p));
    return A;
  }];
}, BUGGY || !SPLIT_WORKS_WITH_OVERWRITTEN_EXEC, UNSUPPORTED_Y);
export default {};